<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="business_type"
                @change="handleBizTypeChange"
              >
                <a-select-option :key="2">暂落箱业务</a-select-option>
                <a-select-option :key="5">客服业务</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="操作员">
              <a-select
                showSearch :filter-option="filterOption"
                allowClear
                placeholder="请选择操作员"
                style="width: 100%"
                v-model="queryParam.operator_id"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="出场日期">
              <a-range-picker @change="onTempDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="箱号">
              <a-input v-model="queryParam.box_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 5">
            <a-form-item label="出库日期">
              <a-range-picker @change="onOutDateChange"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" @click="handleExport">导出</a-button>
    </div>
    <s-table
      ref="table"
      size="middle"
      :dynamicColumns="true"
      :alert="showInfo"
      :rowKey="function(record) { return getRandomCode(8) }"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="boxInfoList" slot-scope="text" v-if="business_type === 2">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 0">
            <span>
              {{ text[0].box_name ? `类型：${text[0].box_name}` : '' }}
              {{ text[0].box_num ? ` 箱号：${text[0].box_num}` : '' }}
              {{ text[0].enter_time ? ` 进场时间：${moment(text[0].enter_time).format('YYYY/MM/DD')}` : '' }}
              {{ text[0].exit_time ? ` 出场时间：${moment(text[0].exit_time).format('YYYY/MM/DD')}` : '' }}
            </span>
            <span style="color: #FAAD14;">{{ text.length !== 0 ? `(${text.length})单` : null }}</span>
          </template>
          <template slot="content">
            <span v-for="item in text" :key="item.id" style="display:block">
              {{ item.box_name ? `类型：${item.box_name}` : '' }}
              {{ item.box_num ? ` 箱号：${item.box_num}` : '' }}
              {{ item.enter_time ? ` 进场时间：${moment(item.enter_time).format('YYYY/MM/DD')}` : '' }}
              {{ item.exit_time ? ` 出场时间：${moment(item.exit_time).format('YYYY/MM/DD')}` : '' }}
            </span>
          </template>
        </a-popover>
      </div>
      <div slot="goodsItem" slot-scope="good" v-if="business_type === 5">
        <div v-for="item in good" :key="item.id">
          <span>{{ item.good_name }} 单位:{{ item.unit }} 数量:{{ item.num }}</span>
        </div>
      </div>
    </s-table>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { getCustomer, downloadExcel } from '@/api/common'
import { wmsOutStat, getWmsDataOption, exportWmsOutSheet } from '@/api/wms'
import { getRandomCode, filterOption } from '@/utils/util'
import debounce from 'lodash/debounce';
import moment from 'moment'
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      business_type: 2,
      showInfo: {
        show: true,
        msg: ''
      },
      columns: [],
      tmpColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '操作员',
          dataIndex: 'operator_id',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '箱信息',
          dataIndex: 'box_info_list',
          scopedSlots: { customRender: 'boxInfoList' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        }
      ],
      cumsColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '业务编号',
          dataIndex: 'operate_num'
        },
        {
          title: '货物信息',
          dataIndex: 'goods',
          scopedSlots: { customRender: 'goodsItem' }
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name'
        },
        {
          title: '出库核注清单号',
          dataIndex: 'clear_num'
        },
        {
          title: '操作员',
          dataIndex: 'operator_id',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '出库总立方',
          dataIndex: 'out_cube'
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_message',
          customRender: (text) => {
            return text && text.split(';').join('、\n')
          },
          width: 120
        },
        {
          title: '出库备注',
          dataIndex: 'out_warehouse_remark',
          width: 200
        },
        {
          title: '反馈备注',
          dataIndex: 'feedback_remark',
          width: 200
        },
        {
          title: '出库日期',
          dataIndex: 'box_time',
          customRender: (text) => {
            return text && text.substring(0, 10)
          },
          width: 150
        }
      ],
      loadData: parameter => {
        return wmsOutStat(this.getBusinessType(this.business_type), Object.assign(parameter, this.queryParam))
          .then(res => {
            if (this.business_type === 5) {
              this.showInfo.msg = '出库总立方：' + res.total_row
            } else {
              this.showInfo.msg = '箱型合计：' + res.total_row.box
            }
            return res
          })
      },
      operatorOps: []
    }
  },
  created() {
    getWmsDataOption('operator').then(v => {
        this.operatorOps = v
    })
    this.columns = this.tmpColumns
  },
  methods: {
    getRandomCode,
    filterOption,
    moment,
    handleBizTypeChange(a) {
      switch (a) {
        case 2:
          this.columns = this.tmpColumns
          break;
        case 5:
          this.columns = this.cumsColumns
          break;
        default:
          this.business_type = 2
          this.columns = this.tmpColumns
          break;
      }
      this.$refs.table.refresh(true)
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        this.customerInfo = undefined
        this.queryParam['customer_id'] = null
      } else {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
      }
      this.customerOps = []
      this.fetching = false
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    getBusinessType(value) {
      switch (value) {
        case 2:
          return 'stat_tmp'
        case 5:
          return 'stat_cums'
        default:
          this.business_type = 2
          this.columns = this.tmpColumns
          return 'stat_tmp'
      }
    },
    onTempDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['exit_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['exit_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['exit_start_time'] = null
        this.queryParam['exit_end_time'] = null
      }
    },
    onOutDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['out_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['out_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['out_start_time'] = null
        this.queryParam['out_end_time'] = null
      }
    },
    handleExport() {
      let type
      if (this.business_type === 2) {
        type = 1 // 暂落箱业务类型
      } else {
        type = 4 // 客服业务类型
      }
      const reqParam = {
        ...this.queryParam,
        'type': type
      }
      exportWmsOutSheet(reqParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    }
  }
}
</script>
